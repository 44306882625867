import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ArrowLeftIcon from 'assets/icons/arrowBack.svg';
import Text from 'components/Text';
import ButtonContainer from 'components/ButtonContainer';
import { useQuery } from 'styles/breakpoints';

interface BackButtonProps {
  onClick: () => void;
  backText?: string;
  hasBackground?: boolean;
}

const Container = styled(ButtonContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ArrowLeftIconStyled = styled(ArrowLeftIcon)<{ hasBackground?: boolean }>`
  width: 1.5rem;
  fill: ${({ theme, hasBackground }) =>
    hasBackground ? theme.colors.light0 : theme.colors.dark100};
  path {
    fill: ${({ theme, hasBackground }) =>
      hasBackground ? theme.colors.light0 : theme.colors.dark100};
  }
`;

const IconContainer = styled.div`
  display: flex;
  // width: 2rem;
  height: 2rem;
  border-radius: 2.1rem;
  // background-color: ${({ theme }) => theme.colors.light60};
  justify-content: center;
  align-items: center;
  margin-right: 0.62rem;
  gap: 12px;
`;

const BackButton: FC<BackButtonProps> = ({
  onClick,
  backText,
  hasBackground,
}) => {
  const { isTablet } = useQuery();
  return (
    <Container onClick={onClick}>
      <IconContainer>
        <ArrowLeftIconStyled hasBackground={hasBackground} />
        {/* {backText && (
          <Text type={isTablet ? 'bodyM500' : 'body500'} color="dark100">
            {backText}
          </Text>
        )} */}
      </IconContainer>
    </Container>
  );
};

export default React.memo(BackButton);
