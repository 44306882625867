import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';
import { useQuery } from 'styles/breakpoints';
import styled from 'styled-components';

interface ProgressCountProps {
  total: number;
  count: number;
  hasBackground?: boolean;
}

const StyledText = styled(Text)<{ hasBackground?: boolean }>`
  color: ${({ hasBackground }) => (hasBackground ? '#fff' : '#000')};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.024rem;
  b {
    color: #f7c800;
  }
`;

const ProgressCount: FC<ProgressCountProps> = ({
  total,
  count,
  hasBackground,
}) => {
  const { isTablet } = useQuery();
  return (
    <StyledText hasBackground={hasBackground}>
      <b>{count}</b> / {total}
    </StyledText>
  );
};

export default ProgressCount;
